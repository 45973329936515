export const PDF_TYPE = 'application/pdf';
export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOC_TYPE = 'application/msword';

export const ALLOWED_DOCUMENT_FORMATS = [PDF_TYPE, DOCX_TYPE, DOC_TYPE];
export const ALLOWED_DOCUMENT_EXTENSIONS = ['.pdf', '.docx', '.doc'];

export const ALLOWED_IMAGE_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];
export const ALLOWED_IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg'];

export const ALLOWED_SRI_FILE_FORMATS = [PDF_TYPE, DOCX_TYPE, ...ALLOWED_IMAGE_FORMATS];
export const ALLOWED_SRI_FILE_EXTENSIONS = ['.pdf', '.docx', ...ALLOWED_IMAGE_EXTENSIONS];

export const ALLOWED_IMAGE_AND_DOCUMENT_FORMATS = [...ALLOWED_DOCUMENT_FORMATS, ...ALLOWED_IMAGE_FORMATS];
export const ALLOWED_IMAGE_AND_DOCUMENT_EXTENSIONS = [...ALLOWED_DOCUMENT_EXTENSIONS, ...ALLOWED_IMAGE_EXTENSIONS];

export const ALLOWED_VIDEO_FORMATS = ['video/mp4', 'video/webm', 'video/quicktime', 'video/mpeg', 'video/ogg', 'audio/ogg', 'video/3gpp'];
export const ALLOWED_VIDEO_EXTENSIONS = ['mp4', 'webm', 'quicktime', 'mpeg', 'ogg', '3gpp'];

export const ALLOW_IMAGE_SIZE = 5242880; // 5MB
